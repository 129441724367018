<template>
    <div class="EvaluateSuccess">
        <el-dialog
            :visible.sync="dialogVisible"
            :show-close="true"
            class="dialog"
            width="648px"
        >
            <img src="@/assets/pj.png" class="img" alt="" />
            <div class="text">{{ text }}</div>
            <div class="back click" @click="back" v-if="noBack">返回首页</div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "EvaluateSuccess",
        props: {
            text: {
                type: String,
                default: "提交成功！",
            },
            noBack: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                dialogVisible: true,
            };
        },
        methods: {
            back() {
                window.localStorage.setItem("navActive", 0);
                this.$store.commit("switchNav", 0);
                this.$router.push({ path: "/" });
            },
        },
    };
</script>

<style lang="less" scoped>
.EvaluateSuccess {
    /deep/.dialog {
        .el-dialog {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 94px 0 79px 0;
            border-radius: 10px;
            .el-dialog__header {
                padding: 0;
            }
            .el-dialog__body {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                .img {
                    width: 151px;
                    height: 154px;
                    margin-bottom: 26px;
                }
                .text {
                    font-size: 20px;
                    font-weight: 500;
                    color: #2d2d2d;
                    margin-bottom: 38px;
                }
                .back {
                    width: 100px;
                    height: 33px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
</style>