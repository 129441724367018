<template>
    <div class="UserCard">
        <img
            class="head"
            :src="user.avatar || require('@/assets/defulatAvatar.png')"
            alt=""
        />
        <div class="ml-18 flex-jsc column">
            <div class="flex-alc">
                <div class="name">{{ user.name }}</div>
                <div class="tag">
                    <img src="@/assets/time.png" class="timeImg" alt="" />
                    <div class="time">
                        在职工龄
                        {{ user.department_info.working_years || "-" }}年
                    </div>
                </div>
            </div>
            <div class="department">
                {{ user.department_info.department }}
                {{
                    user.department_info.position
                        ? "| " + user.department_info.position
                        : ""
                }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserCard",
        props: {
            user: {
                type: Object,
                default: function () {
                    return {
                        avatar: require("@/assets/defulatAvatar.png"),
                        name: "",
                        department_info: {
                            working_years: "",
                            department: "",
                            position: "",
                        },
                    };
                },
            },
        },
    };
</script>

<style lang="less" scoped>
.UserCard {
    display: flex;
    background: white;
    border-radius: 10px;
    padding: 41px 52px;
    .head {
        width: 90px;
        height: 90px;
        border-radius: 100%;
    }
    .name {
        font-size: 24px;
        font-weight: 500;
        color: #2d2d2d;
    }
    .tag {
        background: rgba(0, 0, 0, 0.14);
        border-radius: 11px;
        padding: 2px 9px;
        display: flex;
        align-items: center;
        margin-left: 18px;
        .timeImg {
            width: 11px;
            height: 11px;
            margin-right: 4px;
        }
        .time {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
        }
    }
    .department {
        font-size: 16px;
        font-weight: 400;
        color: #2d2d2d;
        margin-top: 13px;
    }
}
</style>