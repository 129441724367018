<template>
    <el-dialog
        :visible.sync="dialogVisible"
        width="648px"
        class="Evaluate"
        :show-close="show"
        :close-on-press-escape="false"
    >
        <div class="title">{{ title }}</div>
        <el-divider></el-divider>
        <div class="text">{{ text }}</div>
        <div class="flex-alc">
            <div class="name">{{ name }}</div>
            <el-rate v-model="score"></el-rate>
        </div>
        <el-input
            type="textarea"
            :rows="5"
            class="textarea"
            placeholder="说的什么吧..."
            v-model="content"
        >
        </el-input>
        <div class="confirm click" @click="confirm">提交</div>
    </el-dialog>
</template>

<script>
    export default {
        name: "Evaluate",
        props: {
            title: {
                type: String,
                default: "评价师傅",
            },
            text: {
                type: String,
                default: "10/05",
            },
            name: {
                type: String,
            },
        },
        data() {
            return {
                dialogVisible: true,
                score: 0,
                content: "",
                show: true,
            };
        },

        methods: {
            confirm() {
                this.$emit("confirm", { score: this.score, content: this.content });
            },
        },
    };
</script>

<style lang="less" scoped>
.Evaluate {
    /deep/.el-dialog {
        .el-dialog__header {
            padding: 0;
        }
        .el-dialog__body {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .el-divider--horizontal {
                margin: 0;
            }
            .title {
                font-size: 18px;
                font-weight: 500;
                color: #000000;
                padding: 19px 0;
                text-align: center;
            }
            .text {
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                margin-top: 27px;
                margin-bottom: 19px;
            }
            .name {
                font-size: 16px;
                font-weight: 400;
                color: #000000;
                margin-right: 40px;
            }
            .textarea {
                width: 363px;
                margin-top: 26px;
                margin-bottom: 32px;
            }
            .confirm {
                width: 100px;
                height: 32px;
                background: linear-gradient(180deg, #ff3764, #ff2604);
                border-radius: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 60px;
            }
        }
    }
}
</style>